/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Últimamente se estan poniendo de moda las aplicaciones instaladas en el pen que te puedes llevar a cualquier lado, son muy comodas, como por ejemplo como hago yo para llevarme a la facultad el thunderbird y tener siempre el correo a mano o como un amigo que esta enganchado al Photoshop/Gimp y no puede salir de casa sin el. Desde esta pagina es muy comodo usar estas aplicaciones portátiles ", React.createElement(_components.a, {
    href: "http://portableapps.com/"
  }, "portableapps.com")), "\n", React.createElement(_components.p, null, "La ventaja es que todas las aplicaciones que he probado de este sitio funcionan perfectamente con wine, lo que me soluciona el problema de no poder utilizarlas en mi propio PC."), "\n", React.createElement(_components.p, null, "Un saludo"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
